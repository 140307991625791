<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Sửa tư liệu</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminDocumentList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách tư liệu
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Tên tư liệu (*)</label>
              <input v-model="document.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Mô tả tư liệu <i class="far fa-question-circle"
                                                                          :uk-tooltip="'Chỉ ghi lời tư liệu, không ghi tên tư liệu, người trình bày,...'"></i></label>
              <editor id="content" v-model:content="document.content"/>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Nhạc sĩ liên quan</label>
              <multiselect
                  v-model="composers"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchComposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Nghệ sĩ liên quan</label>
              <multiselect
                  v-model="artists"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchArtists"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>
            
            <div class="col-span-2">
              <label class="font-semibold">Soạn giả liên quan</label>
              <multiselect
                  v-model="recomposers"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchRecomposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>
            
            <div class="col-span-2">
              <label class="font-semibold">Nhà thơ liên quan</label>
              <multiselect
                  v-model="poets"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchPoets"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div>
          <label class="font-semibold">File nhạc</label>
          <DropZone type="music" :musicTypeProp="musicType" :playerOptionsProp="playerOptions"
                    v-model:objectIdProp="document.file_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Ảnh minh họa</label>
          <DropZone type="image" :imageProp="image" v-model:objectIdProp="document.image_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Thể loại</label>
          <select v-model="document.type">
            <option value="news">Bài viết</option>
            <option value="audio">Âm thanh</option>
            <option value="video">Video</option>
            <option value="image">Hình ảnh</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Trạng thái</label>
          <select v-model="document.status">
            <option value=1>Đăng</option>
            <option value=0>Nháp</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Đóng góp</label>
          <multiselect
              v-model="uploader"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-7">
          <button type="button"
                  class="mr-2 text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                  v-on:click="updateDocument">
            Cập nhật
          </button>
          <router-link v-if="document.id"
                         :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}"
                         class="mr-2 text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              Xem
            </router-link>
            <router-link v-if="document.id"
                         :to="{name: 'DocumentDetailPreview', params: {id: document.id, slug: document.slug}}"
                         class="text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              Xem trước
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManageDocument, changePageTitle} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminDocumentEdit",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      id: null,
      musicType: "audio",
      playerOptions: {
        autoplay: false,
        loop: false,
        controls: true,
        bigPlayButton: true,
        sources: []
      },
      image: null,
      document: {
        title: "",
        content: "",
        file_id: null,
        image_id: null,
        type: "",
        status: 1,
        file: {
          type: "audio"
        }
      },
      uploader: {
        id: null
      },
      composers: [],
      artists: [],
      recomposers: [],
      poets: []
    }
  },
  methods: {
    loadDocument() {
      let query = `query($id: ID!) {
        document(id: $id) {
          id
          title
          slug
          content
          image_id
          file_id
          type
          status
          file {
            id
            audio
            video
            audio_url
            video_url
            type
          }
          thumbnail {
            id
            url
          }
          uploader {
            id
            username
            avatar {
              url
            }
          }
          composers(first: 100) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          artists(first: 100) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          recomposers(first: 100) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
          poets(first: 100) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.document) {
              this.document = data.data.document;
              this.uploader = this.document.uploader;

              if (this.document.file) {
                this.musicType = this.document.file.type;
                let src = this.document.file.type === "audio" ? this.document.file.audio_url : this.document.file.video_url;
                let type = this.document.file.type === "audio" ? "audio/mp3" : "video/mp4";
                this.playerOptions.sources[0] = {
                  src: src,
                  type: type
                };
              }

              if (this.document.thumbnail) {
                this.image = this.document.thumbnail.url;
              }

              if (this.document.composers && this.document.composers.data) {
                this.composers = this.document.composers.data;
              }
              if (this.document.artists && this.document.artists.data) {
                this.artists = this.document.artists.data;
              }
              if (this.document.recomposers && this.document.recomposers.data) {
                this.recomposers = this.document.recomposers.data;
              }
              if (this.document.poets && this.document.poets.data) {
                this.poets = this.document.poets.data;
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateDocument() {
      if (!this.document.title) {
        this.$toast.error("Bạn chưa nhập tên tư liệu");
        return;
      }

      if ((this.document.type === 'audio' || this.document.type === 'video') && !this.document.file_id) {
        this.$toast.error("Bạn chưa chọn file nhạc");
        return;
      }
      
      if (this.document.type === 'image' && !this.document.image_id) {
        this.$toast.error("Bạn chưa chọn file ảnh");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $file_id: ID, $image_id: ID, $uploader_id: ID, $type: String, $status: Int, $sync_document_composer: [SyncDocumentComposer], $sync_document_artist: [SyncDocumentArtist], $sync_document_recomposer: [SyncDocumentRecomposer], $sync_document_poet: [SyncDocumentPoet]) {
        updateDocument(input: {
          id: $id,
          title: $title,
          content: $content
          file_id: $file_id
          image_id: $image_id
          uploader_id: $uploader_id
          type: $type
          status: $status
          composers: {
            sync: $sync_document_composer
          }
          artists: {
            sync: $sync_document_artist
          }
          recomposers: {
            sync: $sync_document_recomposer
          }
          poets: {
            sync: $sync_document_poet
          }
        }) {
          id
        }
      }`;

      let sync_document_composer = [];
      let sync_document_artist = [];
      let sync_document_recomposer = [];
      let sync_document_poet = [];

      this.composers.map((item) => {
        sync_document_composer.push({id: item.id});
      });
      this.artists.map((item) => {
        sync_document_artist.push({id: item.id});
      });
      this.recomposers.map((item) => {
        sync_document_recomposer.push({id: item.id});
      });
      this.poets.map((item) => {
        sync_document_poet.push({id: item.id});
      });

      ApiService.graphql(query, {
        id: this.document.id,
        title: this.document.title,
        content: this.document.content,
        file_id: this.document.file_id,
        image_id: this.document.image_id,
        type: this.document.type,
        status: parseInt(this.document.status),
        uploader_id: this.uploader ? this.uploader.id : null,
        sync_document_composer: sync_document_composer,
        sync_document_artist: sync_document_artist,
        sync_document_recomposer: sync_document_recomposer,
        sync_document_poet: sync_document_poet
      })
          .then(({data}) => {
            if (data.data && data.data.updateDocument) {
              this.$toast.success("Cập nhật tư liệu thành công");
              window.scrollTo(0, 0);
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchComposers(q) {
      let query = `query($q: Mixed) {
        composers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.composers && data.data.composers.data) {
              return data.data.composers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchArtists(q) {
      let query = `query($q: Mixed) {
        artists(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.artists && data.data.artists.data) {
              return data.data.artists.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchRecomposers(q) {
      let query = `query($q: Mixed) {
        recomposers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.recomposers && data.data.recomposers.data) {
              return data.data.recomposers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchPoets(q) {
      let query = `query($q: Mixed) {
        poets(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.poets && data.data.poets.data) {
              return data.data.poets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
  },
  computed: {
    canManageDocument() {
      return canManageDocument();
    }
  },
  mounted() {
    if (!canManageDocument()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Sửa tư liệu");
    this.id = this.$route.params.id;
    this.loadDocument();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.loadDocument();
    window.scrollTo(0, 0);
    next();
  },
}
</script>
